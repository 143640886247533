<template>
  <v-app>
    <OfflineWarning class="app" v-if="!onLine" />
    <AppLayout v-else>
      <router-view :key="$route.fullPath"></router-view>
    </AppLayout>
  </v-app>
</template>

<script>
import AppLayout from "@/layouts"
import { mapGetters, mapActions } from "vuex"
import OfflineWarning from "./components/layout/OfflineWarning"

export default {
  name: "App",
  components: { AppLayout, OfflineWarning },
  data: () => ({
    message: null,
    onLine: navigator.onLine,
    showBackOnline: false,
  }),
  computed: {
    ...mapGetters(["isAuthenticated", "getUser"]),
  },
  methods: {
    ...mapActions(["createHash", "logout"]),
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === "online"
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true
        setTimeout(() => {
          this.showBackOnline = false
        }, 1000)
      }
    },
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus)
    window.addEventListener("offline", this.updateOnlineStatus)
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus)
    window.removeEventListener("offline", this.updateOnlineStatus)
  },
}
</script>
